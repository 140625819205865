import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching from '../components/images/imageCoaching.js'
import ImageDevelopement from '../components/images/imageDevelopement.js'
import ImageClimat from '../components/images/imageClimat.js'
import ImageChange from '../components/images/imageChange.js'
import ImageConflit from '../components/images/imageConflit.js'
import ImageLeft8 from '../components/images/imageLeft8.js'

const GestionChangement = () => (
  <Layout>
    <SEO title="Gestion du changement | Gestion Mieux-Être" />
    <PageHeader
        header="Gestion du changement"
        text="Les études démontrent que près de 70% des projets de changement n’atteignent pas les objectifs attendus… en plus d’entraîner du cynisme et de la démotivation au sein des organisations. Les organisations investissent trop peu d’efforts à préparer les gestionnaires et leurs employés dans l’appropriation des transformations. "
        quote="Les gens ne résistent pas au changement, ils résistent à être changés<span>- Peter Senge</span>"
        background="changement" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">

        <p class="service-presentation2">Pour déjouer ces statistiques, nous vous guidons avec une démarche de transformation à la fois simple, souple et rigoureuse qui unit les meilleures pratiques de gestion de projets et de gestion du changement.  Notre accompagnement vous permet de : </p>
        <div className="flex-container pad2">
            <div className="half"><ImageLeft8 /></div>
            <div className="half">
                <ul className="success">
                    <li>Maximiser l’engagement continu et concret de vos gestionnaires impliqués</li>
                    <li>Assurer la capacité d’adaptation de vos collaborateurs face au changement permanent </li>
                    <li>Développer un réseau d’ambassadeurs aux différentes étapes de déploiement du changement</li>
                    <li>Mettre en place des outils assurant la cohérence de vos actions</li>


                </ul>
            </div>
        </div>
        <p class="service-presentation2">Les moyens afin de catalyser le potentiel de vos changements sont à votre portée. Avons-nous éveillé votre curiosité?  </p>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        </ScrollAnimation>
    </div>
  </Layout>
)

export default GestionChangement
